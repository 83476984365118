$solarSystem: ("earth", "earth-light");

//Planet propeties
:root {
  --earth-image: url(https://www.solarsystemscope.com/textures/download/2k_earth_nightmap.jpg);
  --earth-tilt: rotate(18deg);
  --earth-day: 1003.9;
  --earth-color: #b3caff;

  --earth-light-image: url(https://www.solarsystemscope.com/textures/download/2k_earth_daymap.jpg);
  --earth-light-tilt: rotate(18deg);
  --earth-light-day: 1003.9;
  --earth-light-color: #000000;
}

//Mixin for styling each planet
@mixin planetization {
  @each $planet in $solarSystem {
    &--#{$planet} {
      .planet__atmosphere {
        box-shadow: inset 10px 0px 12px -2px rgba(255,255,255,.2),
        inset -70px -50px 50px 0px black,
        -5px 0px 10px -4px var(--#{$planet}-color);
      }
      .planet__surface {
        background-image: var(--#{$planet}-image);
        transform: var(--#{$planet}-tilt)scale(1.2);
        animation: planetRotate calc(var(--#{$planet}-day)*.1s) linear infinite;
      }
      .card__planet {
        &::before {
          transform: var(--#{$planet}-tilt)scale(1.2);
          border-color: var(--#{$planet}-color);
          color: var(--#{$planet}-color);
        }
      }
      .tilt__icon {
        transform: var(--#{$planet}-tilt);
        color: var(--#{$planet}-color);
      }
    }
  }
}

//Planet rotation animation
@keyframes planetRotate {
  0%{
    background-position: 0% center;
  }
  100%{
    background-position: -200% center;
  }
}
// End definitions
//---------------------------
//Start styles

.card {
  @include planetization;
}

.card__planet {
  &::before {
    content: '';
    position: absolute;
    height: 55vh;
    z-index: -2;
    left: 50%;
    top: 0%;
    border-left: 1px dashed rgba(255,255,255,.25);
  }
}

.planet {
  &__atmosphere {
    height: 55vh;
    width: 55vh;
    position: relative;
    left: 20px;
    //background: radial-gradient(circle at 30% 50%, rgba(255, 255, 255, .3) 0%, rgba(255, 255, 255, 0) 65%);
    border-radius: 55vh;
    overflow: hidden;
  }

  &__surface {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-size: cover;
  }
}

@media (max-width: 550px) {
  .card__planet {
    &::before {
      content: '';
      position: absolute;
      height: 55vh;
      z-index: -2;
      left: 50%;
      top: 0%;
      border-left: 1px dashed rgba(255,255,255,.25);
    }
  }

  .planet {
    &__atmosphere {
      height: 40vh;
      width: 40vh;
      position: relative;
      left: 20px;
      border-radius: 55vh;
      overflow: hidden;
    }

    &__surface {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-size: cover;
    }
  }
}
