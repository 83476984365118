/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Slabo+27px');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Marck+Script&display=swap');

/* Main Styles */
.container-body {
    font-family: 'Open Sans', sans-serif;
    /*font-family: 'Lobster', cursive;*/
    font-size: 1.3rem;
    line-height: 1.3;
    text-align: center;
}

.container {
    /*height: 600px;*/
    margin: 0 auto;
    padding: 3rem 1rem;
    position: relative;
    width: 90%;
}
.quote-light{
    color: #000;
    text-shadow:  8px 2px 14px rgba(0,0,0,0.27);
}

.quote-dark{
    color: #ffff;
}

#quote-box {
    margin: 0;
    padding: 1rem 2.5rem;
    position: relative;
    text-align: center;
    left: -20px;
}

#quote-box:before {
    content: "\201C";
    font-family: 'Slabo 27px', serif;
    font-size: 20rem;
    height: 20%;
    left: 0;
    line-height: 1;
    opacity: .3;
    position: absolute;
    top: -2rem;
    width: 10%;
}

.quote-font{
    font-family: 'Courgette', cursive;
    margin-left: 1rem;
}

#quote-box p.quote {
    /*font-family: 'Slabo 27px', serif;*/
    font-weight: 500;
    font-size: 2rem;
    letter-spacing: .1rem;
    margin-top: 0;
}

#quote-box p.source {
    font-weight: 200;
}

#quote-box p.source, p.span {
    text-align: right;
}

#quote-box p.source:before {
    content: '- ';
}

#quote-box p span {
    display: block;
    font-size: .9rem;
    font-style: italic;
}

#loadQuote {
    background-color: rgba(0, 0, 68, 0.2);
    border: 2px solid #fff;
    bottom: 30%;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    left: 50%;
    letter-spacing: .4rem;
    padding: 1rem 0;
    position: absolute;
    text-transform: uppercase;
    transform: translateX(-50%);
    width: 60%;
}

#loadQuote:hover {
    border-color: rgba(0, 0, 68, 0.6);
}

#loadQuote:focus {
    outline: none;
}
@media (max-width: 768px) {
    #quote-box:before{
        font-size: 13rem;
    }
}

@media (min-width: 768px) {
    .container {
        width: 60%;
    }
    #quote-box {
        padding: 2rem 4rem;
    }
    #quote-box p.quote {
        font-size: 3rem;
    }
}

.quote-heading {
    font-size: 56px;
    font-weight: 400;
}

.quote-section {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 20px 0px;
    margin: 0px auto;
    margin-top: 2rem;
}

.quote-section > * {
    flex: 1;
    margin-bottom: 30px;
}

.quote-card-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

@media (max-width: 1380px) {
    .quote-card-container {
        padding-top: 0px;
    }
    .quote-heading {
        font-size: 40px;
    }
    .quote-text-subtitle {
        font-size: 18px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .quote-card-container {
        padding-top: 0px;
    }
    .quote-heading {
        font-size: 30px;
        text-align: center;
    }
    .quote-text-subtitle {
        font-size: 16px;
    }
}
