@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: 16px;
    line-height: normal;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
